// // Overwrite Bootstrap's variables here (see _variables.scss in Bootstrap)

// ----- COLORS ------
$blue: rgb(0, 92, 161);
$red: rgb(178, 20, 23);
$yellow: rgb(225, 162, 0);
$green: #3ca306;

$blue-faded: rgb(76, 140, 189);
$red-faded: rgb(201, 90, 92);

$dark: #0f1319;
$light:#ddd;

$gray: #d3d2d2;
$gray-2: #dddddd;
$gray-dark: #a2a2a2;
$gray-darker: #6b6a6a;
$gray-light: #f2f2f2;

$gray-disabled: #e6e6e6;
// // ----- FONTS -----
$font-size-base: 0.8rem;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Open Sans", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// ----- THEMES ------
$primary: $blue;
$secondary: $yellow;
$success: $green;
$info: $blue-faded;
$warning: $red-faded;
$danger: $red;
$light: $light;
$dark: $dark;

// ----- CONTROLS ------
$input-disabled-bg: $gray-disabled !default;

// // ----- PADDING -----
$input-btn-padding-y: 0.2rem;
$input-btn-padding-x: 0.5rem;

$table-cell-padding: 0.3rem !default;
$table-cell-padding-sm: 0.15rem !default;

$card-spacer-y: 0.33rem !default;
$card-spacer-x: 0.75rem !default;

$pagination-padding-y: 0.3rem !default;
$pagination-padding-x: 0.5rem !default;
$pagination-padding-y-sm: 0.1rem !default;
$pagination-padding-x-sm: 0.3rem !default;
$pagination-padding-y-lg: 0.5rem !default;
$pagination-padding-x-lg: 0.75rem !default;
$pagination-line-height: 0.3 !default;

// // $input-btn-padding-y: 0.5rem;
// // $input-btn-padding-x: 1rem;

// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
/*$theme-colors: ( "default": #ddd );*/

// ----- XS BUTTON ------
$font-size-xs: ($font-size-base * .75) !default;
$line-height-xs: 1.5 !default;

$input-btn-padding-y-xs: .125rem !default;

$input-btn-padding-x-xs: .25rem !default;
$input-btn-font-size-xs: $font-size-xs !default;
$input-btn-line-height-xs: $line-height-xs !default;

$btn-padding-y-xs: $input-btn-padding-y-xs !default;

$btn-padding-x-xs: $input-btn-padding-x-xs !default;
$btn-font-size-xs: $input-btn-font-size-xs !default;
$btn-line-height-xs: $input-btn-line-height-xs !default;

$border-radius-xs: .1rem !default;

$btn-border-radius-xs: $border-radius-xs !default;
