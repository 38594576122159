.btn-xs {
    @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
}

.modal-max {
    width: 90%;
    height: 90%;
    max-width: none !important;
    max-height: none !important;
    min-height: 90%;
    min-width: 90%;

    .modal-content {
        height: 100%;
    }
}

.btn-outline-light {
    color: #444;
    border: solid 1px #ddd;

    &.disabled, &:disabled {
        color: #444;
        border: solid 1px #ddd;
    }
}

.disabled {
    cursor: not-allowed;
}
